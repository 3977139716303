var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{ref:"refUserListTable",staticClass:"text-nowrap",staticStyle:{"min-height":"235px"},attrs:{"items":_vm.items,"small":true,"responsive":"","fields":_vm.columns,"primary-key":"id","show-empty":"","empty-text":"اطلاعاتی یافت نشد"},scopedSlots:_vm._u([{key:"cell(coin)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":("/coins/" + (data.item.coin) + ".png"),"variant":"light-info"}})]},proxy:true}],null,true)},[_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" "+_vm._s(_vm.$coins[data.item.coin].persianName)+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$coins[data.item.coin].code))])],1)]}},{key:"cell(balance)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$toLocal(item.balance) || 0)+" ")])]}},{key:"cell(exchangeFee)",fn:function(ref){
var item = ref.item;
return [_c('CryptoCurrencyField',{attrs:{"coin":_vm.coin,"item":item,"target":"exchangeFee","label":"کارمزد صرافی"}})]}},{key:"cell(withdrawalFee)",fn:function(ref){
var item = ref.item;
return [_c('CryptoCurrencyField',{attrs:{"coin":_vm.coin,"item":item,"target":"withdrawalFee","label":"کارمزد برداشت"}})]}},{key:"cell(minWithdraw)",fn:function(ref){
var item = ref.item;
return [_c('CryptoCurrencyField',{attrs:{"coin":_vm.coin,"item":item,"target":"minWithdraw","label":"حداقل برداشت"}})]}},{key:"cell(maxWithdraw)",fn:function(ref){
var item = ref.item;
return [_c('CryptoCurrencyField',{attrs:{"coin":_vm.coin,"item":item,"target":"maxWithdraw","label":"حداکثر برداشت"}})]}},{key:"cell(minAmount)",fn:function(ref){
var item = ref.item;
return [_c('CryptoCurrencyField',{attrs:{"item":item,"target":"minAmount","label":"حداقل سفارش"}})]}},{key:"cell(maxAmount)",fn:function(ref){
var item = ref.item;
return [_c('CryptoCurrencyField',{attrs:{"item":item,"target":"maxAmount","label":"حداکثر سفارش"}})]}},{key:"cell(maxAmountExchange)",fn:function(ref){
var item = ref.item;
return [_c('CryptoCurrencyField',{attrs:{"item":item,"target":"maxAmountExchange","label":"حداکثر سفارش"}})]}},{key:"cell(minAmountExchange)",fn:function(ref){
var item = ref.item;
return [_c('CryptoCurrencyField',{attrs:{"item":item,"target":"minAmountExchange","label":"حداکثر سفارش"}})]}},{key:"cell(creditCardFee)",fn:function(ref){
var item = ref.item;
return [_c('CryptoCurrencyField',{attrs:{"item":item,"target":"creditCardFee","label":"کارمزد کارت ها"}})]}},{key:"cell(tokenTypeDetails)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{attrs:{"variant":"light-primary"},on:{"click":function($event){return _vm.$emit('click',item)}}},[_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"LayersIcon","size":"16"}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }